// Generated by Framer (d31cd55)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {ejemJGr3O: {hover: true}, igJNIEKZo: {hover: true}};

const cycleOrder = ["ejemJGr3O", "igJNIEKZo"];

const serializationHash = "framer-rpW8t"

const variantClassNames = {ejemJGr3O: "framer-v-h4pq5w", igJNIEKZo: "framer-v-lgeqd2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ejemJGr3O", "Variant 2": "igJNIEKZo"}

const getProps = ({height, icon, id, width, ...props}) => { return {...props, UUK26jV0U: icon ?? props.UUK26jV0U ?? "InstagramLogo", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ejemJGr3O"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UUK26jV0U, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ejemJGr3O", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-h4pq5w", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ejemJGr3O"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"ejemJGr3O-hover": {"data-framer-name": undefined}, "igJNIEKZo-hover": {"data-framer-name": undefined}, igJNIEKZo: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-je9uhb-container"} layoutDependency={layoutDependency} layoutId={"bW4xXrYv0-container"}><Phosphor color={"var(--token-0414542f-d7b2-4e50-94a5-6da3ef85ac0b, rgb(153, 153, 153))"} height={"100%"} iconSearch={"House"} iconSelection={UUK26jV0U} id={"bW4xXrYv0"} layoutId={"bW4xXrYv0"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({"ejemJGr3O-hover": {color: "var(--token-0414542f-d7b2-4e50-94a5-6da3ef85ac0b, rgb(153, 153, 158))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1djnbjm-container"} layoutDependency={layoutDependency} layoutId={"hbeRg0HdR-container"} style={{opacity: 0}} variants={{"ejemJGr3O-hover": {opacity: 1}, "igJNIEKZo-hover": {opacity: 1}}}><Phosphor color={"var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(111, 68, 255))"} height={"100%"} iconSearch={"House"} iconSelection={UUK26jV0U} id={"hbeRg0HdR"} layoutId={"hbeRg0HdR"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rpW8t.framer-e9ejs1, .framer-rpW8t .framer-e9ejs1 { display: block; }", ".framer-rpW8t.framer-h4pq5w { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 24px; overflow: visible; padding: 0px; position: relative; width: 24px; }", ".framer-rpW8t .framer-je9uhb-container, .framer-rpW8t .framer-1djnbjm-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rpW8t.framer-h4pq5w { gap: 0px; } .framer-rpW8t.framer-h4pq5w > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rpW8t.framer-h4pq5w > :first-child { margin-left: 0px; } .framer-rpW8t.framer-h4pq5w > :last-child { margin-right: 0px; } }", ".framer-rpW8t.framer-v-lgeqd2.framer-h4pq5w { height: 16px; min-height: unset; width: 16px; }", ".framer-rpW8t.framer-v-h4pq5w.hover .framer-1djnbjm-container { z-index: 2; }", ".framer-rpW8t.framer-v-lgeqd2.hover.framer-h4pq5w { min-height: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"igJNIEKZo":{"layout":["fixed","fixed"]},"n5_o4l_ps":{"layout":["fixed","auto"]},"kwFahrBY6":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"UUK26jV0U":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerz78XOf5EH: React.ComponentType<Props> = withCSS(Component, css, "framer-rpW8t") as typeof Component;
export default Framerz78XOf5EH;

Framerz78XOf5EH.displayName = "Button/Social";

Framerz78XOf5EH.defaultProps = {height: 24, width: 24};

addPropertyControls(Framerz78XOf5EH, {variant: {options: ["ejemJGr3O", "igJNIEKZo"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, UUK26jV0U: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "InstagramLogo", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(Framerz78XOf5EH, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})